import React, { useEffect, useState } from 'react'
import axios from '../../utils/api';

export const TermsAndConditionsContent: React.FC = () => {
    const [termsAndConditionString, setTermsAndCondition] = useState("");

    useEffect(() => {
        axios.get(`api/Catalog/GetPolicy?PolicyId=${process.env.REACT_APP_TERMS_CONDITIONS_GUID}`).then(({ data }) => {
            setTermsAndCondition(data.policy)
        })
    }, [])

    return (
        <div className='header-section-white'>
            <div className='terms_and_conditions_content grid'>
                <div className='terms_and_conditions_info' dangerouslySetInnerHTML={{ __html: termsAndConditionString }}></div>
            </div>
        </div>
    )
}
