import React from 'react'
import { AboutUsCards } from '../../components/about/about-us-cards';
import { AboutUsHeader } from '../../components/about/about-us-header';
import { AboutUsHereHelp } from '../../components/about/about-us-here-help';
import { EmpowerCommunities } from '../../components/about/empower-communities';
import { JoinOurTeam } from '../../components/about/join-our-team';
import { OurStory } from '../../components/about/our-story';
import { OurValues } from '../../components/about/our-values';
// import { ReadyToBecomePartner } from '../../components/about/ready-to-become-partner';
import { Testimonials } from '../../components/about/Testimonials';
import { CorporatePartner } from '../../components/become-a-partner/corporate-partner';

export const About = () => {
    return (
        <div>
            <AboutUsHeader />
            <OurStory />
            <EmpowerCommunities />
            <AboutUsCards />
            <OurValues />
            <JoinOurTeam />
            <Testimonials />
            <AboutUsHereHelp faqGUID={process.env.REACT_APP_ABOUT_FAQ_GUID} />
            {/* <ReadyToBecomePartner/> */}
            <CorporatePartner />
        </div>
    )
}
