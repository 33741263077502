import React from 'react'
import PartnerImg from '../../assets/images/home page images/image 4.png';
import PartnerImgMobile from '../../assets/images/home page images/image4M.png';
import PartnerImgTablet2 from '../../assets/images/home page images/Image4T2.png';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

export const Partner = () => {
    return (
        <div className="header-section-white">
            <div className="partner grid">
                <div className="partner-left-side-content">
                    <p className="partner-first-para font-xsmall">Why become A</p>
                    <h1 className="font-large">Go121 partner</h1>
                    <div>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" preserveAspectRatio="xMinYMin" fill="none">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="partner-third-line font-small">Get your product marketed to your customers by a network of sales agents.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" preserveAspectRatio="xMinYMin" fill="none">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="partner-third-line font-small">Benefit from the 1-2-1 touch provided by vetted, motivated and trusted sales agents within local communities.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" preserveAspectRatio="xMinYMin" fill="none">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="partner-third-line font-small">Leverage the real-time interaction that sales agents have with customers for targeted  surveys, sales and specific offerings.</li>
                        </ul>
                    </div>
                    <NavLink onClick={()=> {window.scrollTo({top:0, left:0, behavior:'smooth'})}} to="/corporate-partner" className='button_action partner-btn-styles'>Become a Partner
                        <span className='btn-w ms-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                            <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>
                    </NavLink>
                </div>
                <div className="partner-right-side-img">
                    <img src={PartnerImg} alt="loading" />
                </div>
                <div className="partner-right-side-img-mobile">
                    <img src={PartnerImgMobile} alt="loading" />
                </div>
                <div className="partner-right-side-img-tablet2">
                    <img src={PartnerImgTablet2} alt="loading" />
                </div>
            </div>
        </div>
    )
}
