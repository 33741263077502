import React from 'react'
import SafetyFirstImg from '../../assets/images/verify an agent/safety_first_img.png';
import LockImg from '../../assets/images/verify an agent/Lock.png';

export const SafetyFirst = () => {
    return (
        <div className='header-section-white'>
            <div className='verify_an_agent_safety_first grid'>
                <div className='verify_an_agent_safety_first_left_side_img'>
                    <img src={SafetyFirstImg} alt="loading" className='safetyFirstMenImg'/>
                </div>
                {/* <div className='verify_an_agent_safety_first_left_side_Lock_img'>
                    <img src={LockImg} alt="loading" />
                </div> */}
                <div className='verify_an_agent_safety_first_right_side_content'>
                    <p className='verify_an_agent_safety_first_right_side_content_first_line font-xsmall'>safety first</p>
                    <h3 className='verify_an_agent_safety_first_right_side_content_second_line font-large'>Know your agent</h3>
                    <p className='verify_an_agent_safety_first_right_side_content_third_line font-small'>Our agents each have a special code that you can find on their lanyard, or any receipt or voucher they have generated for you. Enter this code and if they are a verified agent, you will be able to see what else they can do for you.</p>
                </div>
            </div>
        </div>
    )
}
