import React, { useEffect, useState } from 'react'
import { FaqCard, FaqCategory } from '../../constant/types';
import axios from '../../utils/api';
import { SalesAgent } from '../../components/become-an-agent/sales-agent';
import { HelpCenterHeader } from '../../components/help-center/help-center-header';
import { HelpCenterHeaderCards } from '../../components/help-center/help-center-header-cards';
import { PopularSalesAgent } from '../../components/help-center/popular-sales-agent';

export const HelpCenter: React.FC = () => {

    const [faqCategories, setFaqCategories] = useState<FaqCategory[]>([]);
    const [faqs, setFaqs] = useState<FaqCard[]>([]);
    const [selectedFaqCategory, setSelectedFaqCategory] = useState<FaqCategory | null>(null);

    useEffect(() => {
        initialize();
    }, [])

    useEffect(() => {
        let tagGUID = process.env.REACT_APP_HELP_CENTER_TAG_GUID;
        if (selectedFaqCategory) {
            axios.get(`api/Catalog/GetFAQsFromTagAndCategory?faqCategoryID=${selectedFaqCategory.faqCategoryID}&faqTagID=${tagGUID}`).then(({ data }) => {
                setFaqs(data);
            })
        }
    }, [selectedFaqCategory])

    const initialize = () => {
        let entityID = process.env.REACT_APP_HELP_CENTER_FAQ_CATEGORY_ENTITYID;
        let countryCode = process.env.REACT_APP_HELP_CENTER_FAQ_CATEGORY_COUNTRYCODE;
        axios.get(`api/Catalog/GetFAQCategories?EntityID=${entityID}&CountryCode=${countryCode}`).then(({ data }) => {
            setFaqCategories(data);
            if (data.length > 0) {
                setSelectedFaqCategory(data[0]);
            }
        })
    }

    return (
        <div>
            <HelpCenterHeader
                faqCategories={faqCategories}
                selectedFaqCategory={selectedFaqCategory}
                onClickCategory={setSelectedFaqCategory}
            />
            <HelpCenterHeaderCards faqs={faqs} />
            <PopularSalesAgent category={selectedFaqCategory} />
            <SalesAgent />
        </div>
    )
}
