import React, { useState } from 'react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import loginImg from '../assets/images/home page images/Login.svg';



export const SideNavBar = () => {
    const history = useHistory();
    const [bgColor, setBgColor] = useState("");
    const path = history.location;
    const pathwwww = window.location.href;
    const previousPath = sessionStorage.getItem('previousLink');
    const location = useLocation()

    const handleCloseNavBar = () => {
        history.goBack();
    }
    const handleBgColor = (value: any) => {
        setBgColor(value);
    }
    return (
        <div className='mobile_view_side_nav_bar'>
            <div className='nav_bar_first_div'>
                <span className=''>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 32" fill="none">
                        <path d="M32.7266 16C32.7266 18.2439 33.197 20.3577 34.1042 22.3089C35.0114 24.2601 36.2882 25.9512 37.901 27.3821C39.5138 28.813 41.3954 29.9512 43.5794 30.7642C45.7634 31.5772 48.1154 32 50.6354 32C53.1554 32 55.5074 31.5772 57.6914 30.7642C59.8754 29.9512 61.7906 28.813 63.3698 27.3821C64.9826 25.9512 66.2258 24.2276 67.1666 22.3089C68.0738 20.3577 68.5442 18.2764 68.5442 16C68.5442 13.7561 68.0738 11.6423 67.1666 9.69105C66.2594 7.73983 64.9826 6.04878 63.3698 4.61788C61.757 3.18699 59.8754 2.04878 57.6914 1.23577C55.5074 0.422764 53.1554 0 50.6354 0C48.1154 0 45.7634 0.422764 43.5794 1.23577C41.3954 2.0813 39.4802 3.18699 37.901 4.61788C36.2882 6.04878 35.045 7.73983 34.1042 9.69105C33.197 11.6423 32.7266 13.7236 32.7266 16ZM45.6626 16.1951C45.6626 15.5447 45.797 14.9268 46.0658 14.3415C46.3346 13.7561 46.6706 13.2358 47.141 12.813C47.5778 12.3902 48.1154 12.0325 48.7202 11.7724C49.325 11.5122 49.9634 11.3821 50.6354 11.3821C51.3074 11.3821 51.9458 11.5122 52.5506 11.7724C53.1554 12.0325 53.693 12.3577 54.1298 12.813C54.5666 13.2358 54.9362 13.7561 55.205 14.3415C55.4738 14.9268 55.6082 15.5447 55.6082 16.1951C55.6082 16.8455 55.4738 17.4634 55.205 18.0488C54.9362 18.6341 54.6002 19.1545 54.1298 19.5772C53.693 20 53.1554 20.3577 52.5506 20.6179C51.9458 20.878 51.3074 21.0081 50.6354 21.0081C49.9634 21.0081 49.325 20.878 48.7202 20.6179C48.1154 20.3577 47.5778 20.0325 47.141 19.5772C46.7042 19.1545 46.3346 18.6341 46.0658 18.0488C45.797 17.4959 45.6626 16.878 45.6626 16.1951Z" fill="#FF4600" />
                        <path d="M18.816 32C21.4704 32 23.8224 31.7073 25.8048 31.1219C27.8208 30.5366 29.4 29.8862 30.576 29.1707V13.8211H17.472V20.7805H20.664V22.374C20.5296 22.439 20.328 22.4715 20.1264 22.5041C19.9248 22.5366 19.656 22.5366 19.32 22.5366C18.5136 22.5366 17.7408 22.4065 17.0016 22.1138C16.2624 21.8536 15.5904 21.4634 15.0192 20.9431C14.448 20.4553 13.9776 19.8374 13.6416 19.1219C13.3056 18.4065 13.1376 17.5935 13.1376 16.6829C13.1376 15.8699 13.272 15.0894 13.5744 14.3415C13.8768 13.626 14.3136 12.9756 14.9184 12.4553C15.5232 11.935 16.2624 11.5122 17.1696 11.2195C18.0432 10.9268 19.0848 10.7642 20.2608 10.7642C21 10.7642 21.7728 10.8293 22.6464 10.9268C23.4864 11.0569 24.3264 11.187 25.1664 11.4146C26.0064 11.6097 26.8128 11.8374 27.6192 12.0976C28.4256 12.3577 29.1312 12.6179 29.8032 12.878V2.2439C28.56 1.5935 26.9808 1.07317 25.1328 0.650406C23.2512 0.227642 21.2688 0 19.152 0C16.3296 0 13.776 0.422764 11.424 1.23577C9.072 2.04878 7.056 3.18699 5.376 4.61788C3.6624 6.04878 2.352 7.77235 1.4112 9.75609C0.4704 11.7398 0 13.9187 0 16.2602C0 18.5041 0.4368 20.5528 1.3104 22.4715C2.184 24.3902 3.4272 26.0488 5.0736 27.4797C6.72 28.878 8.7024 30.0162 11.0208 30.7967C13.3392 31.5772 15.9264 32 18.816 32Z" fill="#FF4600" />
                        <path d="M110.947 7.44747V14.5369L114.374 13.8215V26.3418H123.077V4.91089L110.947 7.44747Z" fill="white" />
                        <path d="M73.8193 13.8215V26.3418H82.4881V4.91089L70.3921 7.44747V14.5369L73.8193 13.8215Z" fill="white" />
                        <path d="M91.8289 19.3819L82.9585 31.8697L82.9921 31.9673H108.528V21.9835H101.304L101.271 21.886C102.211 21.073 103.152 20.2274 104.093 19.3819C105.034 18.5039 105.84 17.5933 106.546 16.6177C107.251 15.6421 107.856 14.6014 108.293 13.5282C108.73 12.4551 108.965 11.2843 108.965 10.0486C108.965 8.87785 108.73 7.67459 108.259 6.50386C107.789 5.33313 107.05 4.25996 106.008 3.31687C104.967 2.37378 103.656 1.62582 102.01 1.00793C100.363 0.422566 98.3809 0.129883 95.9953 0.129883C93.7441 0.129883 91.6945 0.357525 89.8465 0.84533C87.9985 1.33313 86.3521 1.88598 84.8737 2.56891V12.2925C85.3441 12.0974 85.8817 11.9022 86.4529 11.6746C87.0241 11.4795 87.6289 11.2843 88.2337 11.1217C88.8385 10.9591 89.4433 10.7965 90.0817 10.699C90.6865 10.6014 91.2913 10.5364 91.8625 10.5364C92.8369 10.5364 93.6769 10.7315 94.3153 11.0892C94.9873 11.4795 95.2897 12.0974 95.2897 12.9429C95.2897 13.3006 95.2225 13.6583 95.0881 14.0486C94.9537 14.4388 94.7521 14.8941 94.4497 15.3819C94.1809 15.8697 93.8113 16.4551 93.3409 17.1055C93.0049 17.7559 92.4673 18.5039 91.8289 19.3819Z" fill="white" />
                    </svg>
                </span>
                <span className='cancel_icon' onClick={() => handleCloseNavBar()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                        <path d="M6 6L18 18M18 6L6 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
            <div className='nav_bar_second_div'>
                <ul className='list-inline'>
                    <li className='m-0 p-0'><NavLink to="/become-go-121-agent" className="text-decoration-none text-white"><p className={`mobile_nav_link_style ${previousPath === '/become-go-121-agent' ? 'link_styles' : ''} ${previousPath === "/" ? "bg_color" : ''}`} onClick={() => handleBgColor("become-an-agent")} >Become an Agent</p></NavLink></li>

                    <li className='m-0 p-0'><NavLink to="/what-is-go121-agent-model" className="text-decoration-none text-white"><p className={`mobile_nav_link_style ${previousPath === '/what-is-go121-agent-model' ? 'link_styles' : ''}`} onClick={() => handleBgColor("what-is-go121-agent-model")}>What is Go121?</p></NavLink></li>

                    <li className='m-0 p-0'><NavLink to="/corporate-partner" className="text-decoration-none text-white"><p className={`mobile_nav_link_style ${previousPath === '/corporate-partner' ? 'link_styles' : ''}`} onClick={() => handleBgColor("corporate-partner")}>Corporate Partner</p></NavLink></li>

                    <li className='m-0 p-0'><NavLink to="/about-direct-sales" className="text-decoration-none text-white"><p className={`mobile_nav_link_style ${previousPath === '/about-direct-sales' ? 'link_styles' : ''}`} onClick={() => handleBgColor("about-direct-sales")}>About</p></NavLink></li>

                    <li className='m-0 p-0'><NavLink to="/help-centre" className="text-decoration-none text-white"><p className={`mobile_nav_link_style ${previousPath === '/help-centre' ? 'link_styles' : ''}`} onClick={() => handleBgColor("help-center")}>Help Centre</p></NavLink></li>

                    {/* <li className='m-0 p-0'>
                        <p className={`mobile_nav_link_style_img ${previousPath === '/corporate-partner' ? 'link_styles' : ''}`} onClick={() => handleBgColor("corporate-partner")}>
                            <NavLink to="/corporate-partner" className="mobile_nav_img text-decoration-none text-white">
                                <span><img src={loginImg} alt="loading" /></span>
                                <span className='mobile_nav_img_text'>Corporate partner login</span>
                            </NavLink>
                        </p>
                    </li>

                    <li className='m-0 p-0'>
                        <p className={`mobile_nav_link_style_img ${previousPath === '/corporate-partner' ? 'link_styles' : ''}`} onClick={() => handleBgColor("corporate-partner")}>
                            <NavLink to='/corporate-partner' className="mobile_nav_img text-decoration-none text-white">
                                <span><img src={loginImg} alt="loading" /></span>
                                <span className='mobile_nav_img_text'>Go 121 agent login</span>
                            </NavLink>
                        </p>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}
