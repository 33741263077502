import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { FaqCard, FaqCategory } from '../../constant/types';
import axios from '../../utils/api';

interface ContextAwareToggleProps {
    children: React.ReactFragment;
    eventKey: string;
    callback?: (arg: string) => void
}

interface ComponentProps {
    category?: FaqCategory | null;
}

export const PopularSalesAgent: React.FC<ComponentProps> = ({ category }) => {

    const [faqCards, setFaqCards] = useState<FaqCard[]>([]);

    useEffect(() => {
        if (category)
            axios.get(`api/Catalog/GetFAQsFromCategory?FAQCategoryID=${category.faqCategoryID}`).then(({ data }) => {
                setFaqCards(data)
            })
    }, [category])

    const ContextAwareToggle: React.FC<ContextAwareToggleProps> = ({ children, eventKey, callback }) => {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <div className={`here_help_right_side_content_padding pt-3 pb-1 ${eventKey === '0' ? 'border-top' : ''}`} onClick={decoratedOnClick}>
                <div className='w-100'>
                    <p className="here_help_right_first_line font-small">{children}</p>
                </div>
                <div className="text-end">
                    <div className="plus_img">
                        {isCurrentEventKey ? (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.0711 12.0712H4.92893" stroke="#FF4600" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    if (category)
        return (
            <div className='header-section-white'>
                <div className="help_center_here_help grid">
                    <div className="agent_faq_left_side_content">
                        <p className="here_help_left_side_first_line font-xsmall">FAQS</p>
                        <h1 className="here_help_left_side_second_line">Popular {category.name} FAQ’s</h1>
                    </div>
                    <div className="agent_faq_right_side_content">
                        <div className="ps-2">
                            <Accordion>
                                {faqCards.map((card: FaqCard, key) => (
                                    <div className='border-bottom'>
                                        <ContextAwareToggle eventKey={key.toString()}>{card.question}</ContextAwareToggle>
                                        <Accordion.Collapse eventKey={key.toString()}>
                                            <>
                                                <div className='here_help_right_side_content_collapse_body mb-3'>
                                                    {card.shortAnswer}
                                                    {card.hasLongAnswer && <span className='here_help_right_side_content_collapse_readmore'>Read More</span>}
                                                </div>
                                            </>
                                        </Accordion.Collapse>
                                    </div>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        )
    else return null;
}
