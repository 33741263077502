import React, { useState } from "react";
import FormMenImg from "../../assets/images/become an agent form/form_bg_desktop.png";
import FormMenImgMobile from "../../assets/images/become an agent form/form_bg_mobile.png";
import Notification, { NotificationState } from "../../components/notification";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "../../utils/api";
import { Link } from "react-router-dom";

const FormSchema = Yup.object({
  cellphone: Yup.string()
    .max(10, "Please enter a valid 10-digit number.")
    .min(10, "Please enter a valid 10-digit number.")
    .matches(new RegExp("[0-9]{10}"), "Please enter a valid 10-digit number.")
    .test(
      "no-leading-zero",
      "Cellphone number should start with 0",
      (value) => {
        return Boolean(value && value.startsWith("0"));
      }
    )
    .required("Please enter a valid 10-digit number."),
});

const CellPhoneInput: React.FC = (props: any) => {
  const proxyApi = process.env.REACT_APP_PROXY_API_URL;
  const [notification, setNotification] = useState<NotificationState>({
    text: "",
    type: "success",
    visible: false,
  });
  let formik = useFormik({
    validationSchema: FormSchema,
    initialValues: {
      cellphone: "",
    },
    onSubmit: (value) => {
      handleSubmit(value.cellphone || "");
    },
  });

  const handleSubmit = (phoneNumber: string) => {
    if (phoneNumber[0] === "0") {
      phoneNumber = "27" + phoneNumber.slice(1, phoneNumber.length);
    }
    axios
      .get(
        `${proxyApi}api/proxy_get/Agent/FindByCellphoneOtp?Cellphone=${phoneNumber}`
      )
      .then(({ data }) => {
        localStorage.setItem("username", data.firstName);
        localStorage.setItem("phoneNumber", data.phoneNumber);
        localStorage.setItem("code", data.agentCode);
        localStorage.setItem("agentId", data.agentId);
        localStorage.setItem("otpId", data.otpId);
        if (data.otpId) {
          props.history.push("/otp");
        } else {
          formik.setErrors({
            cellphone:
              "Sorry, no active application can be found for that cellphone number. Please start a new application. ",
          });
        }
      })
      .catch((error) => {
        setNotification({
          text: "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
          type: "warn",
          visible: true,
        });
      });
  };

  return (
    <div className="nabar-section">
      {notification.visible && (
        <Notification type={notification.type} text={notification.text} />
      )}
      <div className="cellphone_input_form grid">
        <div className="cellphone_input_form_left_side_form">
          <p className="cellphone_input_breadcrumb">
            <Link to={"/become-go-121-agent"}>
              <span className="cellphone_input_breadcrumb_first">
                Become an Agent
              </span>
            </Link>
            <span className="cellphone_input_breadcrumb_second">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6 3L11 8L6 13"
                  stroke="#FF4600"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Personal details
            </span>
          </p>
          <div className="cellphone_input_form_container">
            <form onSubmit={formik.handleSubmit}>
              <div className="cellphone_input_form_header">
                <div className="cellphone_input_form_title">Welcome Back!</div>
                <div className="cellphone_input_form_step">Step 1/3</div>
              </div>
              <div className="cellphone_input_form_body">
                <div className="cellphone_input_form_group">
                  <p className="cellphone_input_form_description">
                    To continue, please enter your cellphone number below
                  </p>
                </div>
                <div className="cellphone_input_form_group">
                  <label
                    htmlFor=""
                    className={`cellphone_input_form_label ${
                      formik.touched.cellphone && formik.errors.cellphone
                        ? "error"
                        : ""
                    }`}
                  >
                    Cellphone number
                  </label>
                  <input
                    type="text"
                    className={`cellphone_input_form_input ${
                      formik.touched.cellphone && formik.errors.cellphone
                        ? "error"
                        : ""
                    }`}
                    placeholder="Enter your number"
                    {...formik.getFieldProps("cellphone")}
                  />
                  {formik.touched.cellphone && formik.errors.cellphone && (
                    <div className="cellphone_input_form_error">
                      {formik.errors.cellphone}
                    </div>
                  )}
                </div>
                <div className="cellphone_input_form_group">
                  <button
                    type="submit"
                    className="cellphone_input_form_submit_btn"
                  >
                    <span>Next</span>
                    <svg
                      width="22"
                      height="24"
                      viewBox="0 0 22 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18"
                        stroke="#FFBF00"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="cellphone_input_extra_info">
                  Already have an account?{" "}
                  <a href="https://agent.instaccess.com">Sign in</a>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="cellphone_input_form_right_side_img">
          <img src={FormMenImg} alt="loading" />
        </div>
        <div className="cellphone_input_form_right_side_img_mobile">
          <img src={FormMenImgMobile} alt="loading" />
        </div>
      </div>
    </div>
  );
};

export { CellPhoneInput };
