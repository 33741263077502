import React from 'react'
import { Link } from 'react-router-dom';
import rightArrowInputFieldImg from '../../assets/images/right_arrow_input_field.png';

export const VerifyAnAgentHeader = () => {
    return (
        <div className='header-section'>
            <div className='verify_an_agent_header grid'>
                <div className='verify_an_agent_header_center_content'>
                    <h1 className='verify_an_agent_header_center_content_first_line font-large'>Verify a Go121 sales agent</h1>
                    <p className='verify_an_agent_header_center_content_second_line font-medium'>Search for an agent by entering the agent’s ID  printed on the back of their lanyard</p>
                    <div className="input-group verify_an_agent_header_center_content_input_group">
                        <input type="text" className="form-control border-0 help_center_header_input_font_styles font-small" placeholder="Enter agent ID" aria-label="Username" style={{ boxShadow: "none" }} />
                        <span className="input-group-text bg-white border-0"><Link to="/verify-an-angent-error"><img src={rightArrowInputFieldImg} alt="loading" className='img_class' /></Link></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
