import React from 'react'
import { HelpCenterHeader } from '../../components/help-center/help-center-header';
import { HelpCenterHeaderCards } from '../../components/help-center/help-center-header-cards';
import { PopularSalesAgent } from '../../components/help-center/popular-sales-agent';
import { PopularSalesAgentBelowCards } from '../../components/help-center/popular-sales-agent-below-cards';
import { SalesAgent } from '../../components/become-an-agent/sales-agent';
import { StillHaveQuestionError } from '../../components/help-center-error/still-have-question-error';

export const HelpCenterError = () => {
    return (
        <div>
            <HelpCenterHeader faqCategories={[]} />
            <HelpCenterHeaderCards faqs={[]} />
            <PopularSalesAgent />
            <PopularSalesAgentBelowCards />
            <StillHaveQuestionError />
            <SalesAgent />
        </div>
    )
}
