import { Switch, Redirect } from "react-router-dom";
import routes from "./routes";
import { PrivateRoute } from "../privateRoute";

export const Routes = () => {
  return (
    <Switch>
      {routes.map((route, index) => (
        <PrivateRoute
          key={index}
          path={route.path}
          exact={true}
          component={(props: any) =>
            route.layout ? (
              <route.layout>
                <route.component {...props} />
              </route.layout>
            ) : (
              <route.component {...props} />
            )
          }
        />
      ))}
      <Redirect to="/error-404" />
    </Switch>
  );
};
