import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import desktopImg from "../../src/assets/images/Desktop.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../utils/api";

interface Props {
  show: boolean;
  onHide?: () => void;
}

interface FormData {
  name: string;
  cell_number: string;
  email_address: string;
}

const FormSchema = Yup.object({
  name: Yup.string().required("Please Enter Your Name"),
  cell_number: Yup.string()
    .max(10, "Enter Valid Cell Number")
    .min(10, "Enter Valid Cell Number")
    .matches(new RegExp("^0[0-9]{9}.*$"), "Enter Valid Cell Number")
    .required("Enter Valid Cell Number"),
  email_address: Yup.string()
    .email("Enter Valid Email")
    .required("Enter Valid Email"),
});

export const FromPopup: React.FC<Props> = (props) => {
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);
  const [isReCaptcha, setIsReCaptcha] = useState(false);
  const [isCheckReCaptcha, setIsCheckReCaptcha] = useState(false);
  const [isEmailUsed, setIsEmailUsed] = useState(false);
  const [isMobileNumberUsed, setIsMobileNumberUsed] = useState(false);

  const formik = useFormik({
    validationSchema: FormSchema,
    initialValues: {
      name: "",
      cell_number: "",
      email_address: "",
    },
    onSubmit: (value: FormData) => {
      if (isReCaptcha) {
        axios
          .post(
            `${process.env.REACT_APP_AGENT_SIGN_UP_API_BASE_URL}local_api/index.php/api/agent-details-add`,
            value
          )
          .then((res: any) => {
            if (res.status === "ERROR") {
              if (res?.data?.error === "Email already used") {
                setIsEmailUsed(true);
              } else if (res?.data?.error === "Cell Number already used") {
                setIsEmailUsed(false);
                setIsMobileNumberUsed(true);
              }
            } else {
              setShowThankYouPopup(true);
            }
          });
      } else {
        setIsCheckReCaptcha(true);
      }
    },
  });

  const onChangeRecaptcha = (value: any) => {
    setIsReCaptcha(value);
    if (!value) {
      setIsCheckReCaptcha(true);
    } else {
      setIsCheckReCaptcha(false);
    }
  };
  const handleCloseModal = () => {
    if (props.onHide) {
      props.onHide();
    }
  };

  return (
    <>
      <div>
        <Modal
          show={props.show}
          onHide={props.onHide}
          centered
          className="partner_sign_up_form_popup"
        >
          <Modal.Body className="partner_sign_up_form_popup_body">
            {!showThankYouPopup && (
              <div className="partner_sign_up_form_second_row_right_side_content">
                <div className="sign_up_form_content sign_up_form_content_popuo">
                  <form onSubmit={formik.handleSubmit}>
                    <div>
                      <p className="sign_up_form_content_first_line font-medium">
                        Submit your details
                      </p>
                      <div className="su_your_de_margin_b">
                        <label
                          htmlFor="name"
                          className="form-label sign_up_form_lable_name font-xsmall"
                        >
                          Your name
                        </label>
                        <input
                          type="text"
                          className="form-control input_field_bg_color input_bg_color pop_up font-xsmall border-0"
                          id="name"
                          placeholder="Enter your full name"
                          {...formik.getFieldProps("name")}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="error_messages_styles font-xsmall">
                            {formik.errors.name}
                          </div>
                        )}
                      </div>
                      <div className="su_your_de_margin_b">
                        <label
                          htmlFor="cellphone_number"
                          className="form-label sign_up_form_lable_name font-xsmall"
                        >
                          Cellphone number
                        </label>
                        <input
                          type="text"
                          className="form-control input_field_bg_color input_bg_color pop_up font-xsmall border-0"
                          id="cellphone_number"
                          placeholder="Enter your cellphone number"
                          {...formik.getFieldProps("cell_number")}
                        />
                        {formik.touched.cell_number &&
                        formik.errors.cell_number ? (
                          <div className="error_messages_styles font-xsmall">
                            {formik.errors.cell_number}
                          </div>
                        ) : (
                          <>
                            {isMobileNumberUsed && (
                              <div className="error_messages_styles font-xsmall">
                                Cell Number already used
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="su_your_de_margin_b">
                        <label
                          htmlFor="email"
                          className="form-label sign_up_form_lable_name font-xsmall"
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control input_field_bg_color input_bg_color pop_up font-xsmall border-0"
                          id="email"
                          placeholder="Enter your email address"
                          {...formik.getFieldProps("email_address")}
                        />
                        {formik.touched.email_address &&
                        formik.errors.email_address ? (
                          <div className="error_messages_styles font-xsmall">
                            {formik.errors.email_address}
                          </div>
                        ) : (
                          <>
                            {isEmailUsed && (
                              <div className="error_messages_styles font-xsmall">
                                Email already used
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="text-center i_am_not_robot_img">
                        {/* for test sitekey="6LfVVGUeAAAAAMh00GNJL6MYdqLYIi5iFvb0D3uK" */}
                        <ReCAPTCHA
                          sitekey="6Lexn2UeAAAAAFY6T3jmZLrRbcwjeoeVK12M-KQB"
                          onChange={onChangeRecaptcha}
                        />
                      </div>
                      {isCheckReCaptcha && (
                        <label className="error_messages_styles font-xsmall">
                          {" "}
                          Please complete CAPTCHA
                        </label>
                      )}
                    </div>
                    <div className="text-center">
                      <button
                        className="button_action become_an_agent_thank_you_close_btn"
                        type="submit"
                      >
                        Submit
                        <span className="btn-w ms-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 24"
                            fill="none"
                          >
                            <path
                              d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18"
                              stroke="#FFBF00"
                              stroke-width="3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {showThankYouPopup && (
              <div className="application_form_complete">
                <div className="application_form_complete_content">
                  <img src={desktopImg} alt="loading" />
                  <h1 className="application_form_complete_content_first_linepp font-mlarge">
                    Thank you
                  </h1>
                  <p className="application_form_complete_content_second_line font-small">
                    <p className="mb-0"> We have your details!</p>
                    <p className="mb-0">
                      {" "}
                      As soon as we start accepting applications
                    </p>
                    <p className="mb-0"> to become a Go121 Agent, we will</p>
                    <p className="mb-0"> message you.</p>
                    <p className="mb-0">
                      {" "}
                      Please check back soon for any updates.
                    </p>
                  </p>
                  <div className="mb-3 text-center">
                    <button
                      className="button_action become_an_agent_thank_you_close_btn"
                      onClick={() => handleCloseModal()}
                    >
                      Close
                      <span className="btn-w ms-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 22 24"
                          fill="none"
                        >
                          <path
                            d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18"
                            stroke="#FFBF00"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
