
import { HomePage } from '../pages/home/home-page'
import { whatIsGo121AgentModel } from '../pages/become-an-agent/become-an-agent'
import { ApplicationFormComplete } from '../pages/application-form-complete/application-form-complete'
import { PersonalDetailsForm } from '../pages/become-an-agent-form-2/personal-details'
import { Otp } from '../pages/become-an-agent-form-5/otp'
import { AddressDetails } from '../pages/address-details-form 4/address-details'
import { WhatIsGo } from '../pages/what-is-go121/what-is-go'
import { BecomeaPartner } from '../pages/become-a-partner/become-a-partner'
import { SignupForm } from '../pages/partner-signup-form/signup-form'
import { SignupFormError } from '../pages/partner-signup-form/signup-form-error'
import { SignupFormThankYou } from '../pages/partner-signup-form/signup-form-thank-you'
import { About } from '../pages/about/about'
import { HelpCenter } from '../pages/help-center/help-center'
import { HelpCenterError } from '../pages/help-center-error/help-center-error'
import { HelpCenterThankYou } from '../pages/help-center-thank-you/help-center-thank-you'
import { HelpCenterTopic } from '../pages/help-center-topic/help-center-topic'
import { helpCenterSearchOnResult } from '../pages/help-center-search-on-result/help-center-search-on-result'
import { HelpCenterArticale } from '../pages/help-center-articale/help-center-articale'
import { VerifyAnAgent } from '../pages/verify-an-agent/verify-an-agent'
import { VerifyAnAgentError } from '../pages/verify-an-agent-error/verify-an-agent-error'
import { VeriffyAnAgentSuccess } from '../pages/verify-an-agent-success/veriffy-an-agent-success'
import { TermsAndConditions } from '../pages/terms and conditions/terms-and-conditions'
import { PrivacyPolicy } from '../pages/privacy-policy/privacy-policy'
import { RicaPolicy } from '../pages/rica-policy/rica-policy'
import { ErrorPage } from '../pages/error-page/error-page'
import { ThankYouPage } from '../pages/thank-you/thank-you-page'
import { ErrorMessage } from '../pages/error-message/error-message'
import { SideNavBar } from '../components/side-nav-bar'
import { CellPhoneInput } from '../pages/become-an-agent-form-alt/cell-phone-input'
import { MainLayout } from '../layouts/main-layout'

// routes
const routes = [
    { path: "/", component: HomePage, exact: true, layout: MainLayout},
    { path: "/home", component: HomePage, exact: true, layout: MainLayout},
    { path: "/become-go-121-agent", component: whatIsGo121AgentModel, exact: true, layout: MainLayout},
    { path: "/personal-details", component: PersonalDetailsForm, exact: true, layout: MainLayout},
    { path: "/otp", component: Otp, exact: true, layout: MainLayout},
    { path: "/address-details", component: AddressDetails, exact: true, layout: MainLayout},
    { path: "/cell-phone-input", component: CellPhoneInput, exact: true, layout: MainLayout},
    { path: "/application-form-complete", component: ApplicationFormComplete, exact: true, layout: MainLayout},
    { path: "/what-is-go121-agent-model", component: WhatIsGo, exact: true, layout: MainLayout},
    { path: "/corporate-partner", component: BecomeaPartner, exact: true, layout: MainLayout},
    { path: "/signup-form", component: SignupForm, exact: true, layout: MainLayout},
    { path: "/signup-form-error", component: SignupFormError, exact: true, layout: MainLayout},
    { path: "/signup-form-thank-you", component: SignupFormThankYou, exact: true, layout: MainLayout},
    { path: "/about-direct-sales", component: About, exact: true, layout: MainLayout},
    { path: "/help-centre", component: HelpCenter, exact: true, layout: MainLayout},
    { path: "/help-centre-error", component: HelpCenterError, exact: true, layout: MainLayout},
    { path: "/help-centre-thank-you", component: HelpCenterThankYou, exact: true, layout: MainLayout},
    { path: "/help-centre-topic", component: HelpCenterTopic, exact: true, layout: MainLayout},
    { path: "/help-centre-search-on-result", component: helpCenterSearchOnResult, exact: true, layout: MainLayout},
    { path: "/help-centre-articale", component: HelpCenterArticale, exact: true, layout: MainLayout},
    { path: "/verify-an-angent", component: VerifyAnAgent, exact: true, layout: MainLayout},
    { path: "/verify-an-angent-error", component: VerifyAnAgentError, exact: true, layout: MainLayout},
    { path: "/verify-an-angent-success", component: VeriffyAnAgentSuccess, exact: true, layout: MainLayout},
    { path: "/terms-conditions", component: TermsAndConditions, exact: true, layout: MainLayout},
    { path: "/privacy-policy", component: PrivacyPolicy, exact: true, layout: MainLayout},
    { path: "/rica-policy", component: RicaPolicy, exact: true, layout: MainLayout},
    { path: "/thank-you", component: ThankYouPage, exact: true, layout: MainLayout},
    { path: "/error-message", component: ErrorMessage, exact: true, layout: MainLayout},
    { path: "/nav-link", component: SideNavBar, exact: true, layout: MainLayout},
    { path: "/error-404", component: ErrorPage, exact: true, layout: MainLayout},
];

export default routes;