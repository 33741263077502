import React from 'react';
import { Route } from "react-router-dom";

interface PrivateRouteProps {
    component: any;
    path: string;
    exact: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, path, exact, ...rest }) => (
    <Route
        {...rest}
        path={path}
        exact={exact}
        render={props =>
            (<Component {...props} />)
        } />
);