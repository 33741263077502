import React from 'react'
import { Link } from 'react-router-dom';
// import arrowButtonImg from '../../assets/images/home page images/Go121 Arrow.svg';
import Desktopimg from '../../assets/images/home page images/Image 6.png';
import MobileImg from '../../assets/images/home page images/Image 6M.png';
import Tablet2Img from '../../assets/images/home page images/Image6T2.png';
import { NavLink } from 'react-router-dom';
// import ApplyNowbtnImg from '../../assets/images/home page images/apply_now_btn.png';

export const ReadyToBecomeSalesAgent = () => {
    return (
        <div className="sales-section">
            <div className="ready-to-become-sales-agent grid">
                <div className="ready-to-become-sales-agent-heading">
                    <p className="ready-to-become-sales-agent-font-styles font-mlarge">Ready to become a Go121 sales agent?</p>
                    <NavLink onClick={() =>{window.scrollTo({top:0, left:0, behavior:"smooth"})}} to="/become-go-121-agent" className='button_action apply_now_btm_mrg' >Apply Now
                        <span className='btn-w ms-4'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                            <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>
                    </NavLink>
                </div>
                <div className="ready-to-become-sales-agent-img">
                    <img src={Desktopimg} alt="loading" />
                </div>
                <div className="ready-to-become-sales-agent-img-mobile">
                    <img src={MobileImg} alt="loading" />
                </div>
                <div className="ready-to-become-sales-agent-img-tablet2">
                    <img src={Tablet2Img} alt="loading" />
                </div>
            </div>
        </div>
    )
}
