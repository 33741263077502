import React from 'react'
// import GirlImg from "../../assets/images/home page images/Image3.png";
// import { Link } from 'react-router-dom';
import WomenImg from '../../assets/images/home page images/woman_pic_group.png';
import WomenImgMobile from '../../assets/images/home page images/Image3M.png';
import WomenImgTablet2 from '../../assets/images/home page images/Image3T2.png';
import { NavLink } from 'react-router-dom';
import YellowEclipseImg from '../../assets/images/home page images/Ellipse_yellow.svg';
import greenArrowImg from '../../assets/images/home page images/Green_Arrow.svg';
import redArrowImg from '../../assets/images/home page images/Red_Arrow.svg';
// import Aos from 'aos';
// import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css';

export const SalesAgent = () => {
    // Aos.init();
    return (
        <div className="header-section-white">
            <div className="sales-agent grid">
                <div className='sales-agent_images_div'>
                        <div className="sales-agent-left-side-img1" >
                            {/* <ScrollAnimation animateIn='fadeInUpBig' animateOut='fadeOut'> */}
                                {/* <img src={WomenImg} alt="loading" className='womenPic' data-aos="fade-up" data-aos-duration="2000" /> */}
                                <img src={WomenImg} alt="loading" className='womenPic' />
                            {/* </ScrollAnimation> */}
                        </div>
                        <div className="sales-agent-left-side-img1-mobile" >
                            {/* <ScrollAnimation animateIn='fadeInUpBig' animateOut='fadeOut'> */}
                                {/* <img src={WomenImg} alt="loading" className='womenPic' data-aos="fade-up" data-aos-duration="2000" /> */}
                                <img src={WomenImgMobile} alt="loading" className='womenPic' />
                            {/* </ScrollAnimation> */}
                        </div>
                        <div className="sales-agent-left-side-img1-tablet2" >
                            {/* <ScrollAnimation animateIn='fadeInUpBig' animateOut='fadeOut'> */}
                                {/* <img src={WomenImg} alt="loading" className='womenPic' data-aos="fade-up" data-aos-duration="2000" /> */}
                                <img src={WomenImgTablet2} alt="loading" className='womenPic' />
                            {/* </ScrollAnimation> */}
                        </div>
                        
                    {/* <img src={GirlImg} alt="loading" /> */}

                    {/* <div className="sales-agent-left-side-img1" data-aos="fade-up" data-aos-duration="3000" data-aos-delay="1600">
                        <img src={WomenImg} alt="loading" className='womenPic' />
                    </div>
                    <div className='sales-agent-left-side-img2' data-aos="zoom-in" data-aos-duration="1500">
                        <img src={YellowEclipseImg} alt="loading" className='yellowEclipseImg' />
                    </div>
                    <div className='sales-agent-left-side-img3'>
                        <img src={greenArrowImg} alt="loading" className='grrenArrowImg' data-aos="fade-up" data-aos-duration="1500" data-aos-delay="3000" />
                    </div>
                    <div className='sales-agent-left-side-img4'>
                        <img src={redArrowImg} alt="loading" className='redArrowImg' data-aos="fade-up" data-aos-duration="1500" data-aos-delay="3000" />
                    </div> */}
                    {/* <div className='sales-agent-left-side-img5'>
                    </div> */}
                </div>
                {/* <div className="sales-agent-right-side-content" data-aos="fade-up" data-aos-duration="2000"> */}
                <div className="sales-agent-right-side-content" >
                    {/* <ScrollAnimation animateIn='fadeInUpBig' animateOut='fadeOut'> */}
                        <p className="sales-agent-first-para font-xsmall">Why become A</p>
                        <h1 className="font-large">Go121 sales agent</h1>
                        <div>
                            <ul className="list-inline d-flex">
                                <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" preserveAspectRatio="xMinYMin" fill="none">
                                    <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                                </svg></li>
                                <li className="sale-agent-third-line font-small">Earn a monthly income and save on transport costs by working directly in your community.</li>
                            </ul>
                            <ul className="list-inline d-flex">
                                <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" preserveAspectRatio="xMinYMin" fill="none">
                                    <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                                </svg></li>
                                <li className="sale-agent-third-line font-small">Get FREE training on an ever-growing bundle of products and services.</li>
                            </ul>
                            <ul className="list-inline d-flex">
                                <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" preserveAspectRatio="xMinYMin" fill="none">
                                    <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                                </svg></li>
                                <li className="sale-agent-third-line font-small">Get ongoing support from a team that wants you to succeed. </li>
                            </ul>
                        </div>
                        <NavLink onClick={()=> {window.scrollTo({top:0, left:0, behavior:'smooth'})}} to="/become-go-121-agent" className='button_action sales-agent-btn-styles'>Become an Agent
                            <span className='btn-w ms-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                                <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </span>
                        </NavLink>
                    {/* </ScrollAnimation> */}
                </div>
            </div>
        </div>
    )
}
