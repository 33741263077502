import React from 'react'
import { HereHelp } from '../../components/become-an-agent/here-help';
import { SalesAgent } from '../../components/become-an-agent/sales-agent';
// import { ReadyToBecomeSalesAgent } from '../../components/home/ready-to-become-sales-agent';
import { CommunityFirst } from '../../components/what is go121/community-first';
import { Header } from '../../components/what is go121/header';
import { MeetOurAgent } from '../../components/what is go121/meet-our-agent';
import { ProductsAndServices } from '../../components/what is go121/products-and-services';


export const WhatIsGo = () => {
    return (
        <div>
            <Header/>
            <CommunityFirst/>
            <ProductsAndServices/>
            <MeetOurAgent/>
            <HereHelp faqGUID={process.env.REACT_APP_WHAT_IS_GO121_FAQ_GUID}/>
            <SalesAgent/>
        </div>
    )
}
