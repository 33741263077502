import React from 'react'
import { Link } from 'react-router-dom';
import SecondImg from '../../assets/images/home page images/Image2.png'
import SecondImgMobile from '../../assets/images/home page images/Image 2M.png'
import SecondImgTablet2 from '../../assets/images/home page images/Image2T2.png'
import { NavLink } from 'react-router-dom';

export const BrandsBenefit = () => {
    return (
        <div className="header-section-white">
            <div className="brand-benefit grid">
                <div className="brand-benefit-content">
                    <div className="brand-benefit-content-header font-xsmall">what is go121</div>
                    <div className="brand-benefit-content-title font-large">
                        How do agents and brands benefit?
                    </div>
                    <p className="brand-benefit-content-text font-small">We provide products and services to hard-to-reach markets via a network of independent sales agents. Most importantly, we give our agents the tools to create their own income by selling much-wanted products and services to their friends and neighbours on a 1-2-1 basis.</p>
                    <NavLink onClick={()=> {window.scrollTo({top:0, left:0, behavior:'smooth'})}} to="/what-is-go121-agent-model" className='button_action'>Learn more
                        <span className='btn-w ms-4'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                            <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>
                    </NavLink>
                </div>
                <div className="brand-benefit-image">
                    <img src={SecondImg} alt="loading" />
                </div>
                <div className="brand-benefit-image-mobile">
                    <img src={SecondImgMobile} alt="loading" />
                </div>
                <div className="brand-benefit-image-tablet2">
                    <img src={SecondImgTablet2} alt="loading" />
                </div>
            </div>
        </div>
    )
}
