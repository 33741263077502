import React from 'react'
import { RicaPolicyContent } from '../../components/rica-policy/rica-policy-content'
import { RicaPolicyHeader } from '../../components/rica-policy/rica-policy-header'

export const RicaPolicy = () => {
    return (
        <div>
           <RicaPolicyHeader/> 
           <RicaPolicyContent/>
        </div>
    )
}
