import React from 'react'
import { TermsAndConditionsContent } from '../../components/terms and conditions/terms-and-conditions-content'
import { TermsAndConditionsHeader } from '../../components/terms and conditions/terms-and-conditions-header'

export const TermsAndConditions = () => {
    return (
        <div>
            <TermsAndConditionsHeader/>
            <TermsAndConditionsContent/>
        </div>
    )
}
