import React from 'react'
import InputFieldCancelImg from '../../assets/images/input_field_cancel_pic.png';
import RoundedWarningImg from '../../assets/images/round_warning_img.png';

export const VerifyAnAgnetHeaderError = () => {
    return (
        <div className='header-section'>
            <div className='verify_an_agent_header grid'>
                <div className='verify_an_agent_header_center_content'>
                    <h1 className='verify_an_agent_header_center_content_first_line font-large'>Verify a Go121 sales agent</h1>
                    <p className='verify_an_agent_header_center_content_second_line font-medium'>Search for an agent by entering the agent’s ID  printed on the back of their lanyard</p>
                    <div className="input-group verify_an_agent_header_center_content_input_group">
                        <input type="text" className="form-control border-0 help_center_header_input_font_styles font-small" placeholder="Enter agent ID" aria-label="Username" style={{ boxShadow: "none" }} />
                        <span className="input-group-text bg-white border-0"><img src={InputFieldCancelImg} alt="loading" className='img_class' /></span>
                    </div>
                    <div className='verify_an_agent__header_error_content'>
                        <div className='text-center'>
                            <span><img src={RoundedWarningImg} alt="loading" /></span>
                            <span className='mx-2 verify_an_agent__header_error_text font-small'>We’re sorry. We cannot find an agent with this ID</span>
                            <div>
                               <span className='verify_an_agent__header_error_text font-small'>Please try another number</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
