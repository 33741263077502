import React from 'react'
// import BeYOurOwnBossLeftSideImg from '../../assets/images/become an agent/be your own boss left side image.svg';
// import OneForYouImg from '../../assets/images/become an agent/one for you.svg';
// import TransforImg from '../../assets/images/become an agent/transfor.svg';
// import MobileImg from '../../assets/images/become an agent/mobile_img.png';
import Be_own_boss_img from '../../assets/images/become an agent/mobile_img2.png';
import Be_own_boss_img_mobile from '../../assets/images/become an agent/mobile_imgM.png';

export const BeYourOwnBoss = () => {
    return (
        <div className="header-section-white">
            <div className="be_your_own_boss grid">
                <div className="be_your_own_boss_left_side_img">
                    <img src={Be_own_boss_img} alt="loading" />
                </div>
                <div className="be_your_own_boss_left_side_img_mobile">
                    <img src={Be_own_boss_img_mobile} alt="loading" />
                </div>
                <div className="be_your_own_boss_right_side_content">
                    <p className="be_your_own_boss_right_side_content_first_line font-xsmall">Why become an agent</p>
                    <h1 className="be_your_own_boss_right_side_content_second_line font-large">Work your own hours. Be your own boss.</h1>
                    <div>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="be_your_own_boss_right_side_content_third_line font-small">It’s an easy way to earn money and support your family.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="be_your_own_boss_right_side_content_third_line font-small">Sell directly “1-2-1” to your customers. </li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="be_your_own_boss_right_side_content_third_line font-small">Earn a monthly income. The more you sell, the more you earn.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
