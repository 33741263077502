import React from 'react'
import { Link } from 'react-router-dom';
import OkImg from '../../assets/images/ok_pic.png';
import OkImgMobile from "../../assets/images/ok_pic_mobile.png";
import ThankyouImg from '../../assets/images/thank_you_page_img.png';

export const SignupFormThankYou: React.FC = () => {
    return (
        <div className="header-section">
            <div className="partner_sign_up_form_new grid">
                <div className="partner_sign_up_form_first_row">
                    <p className='partner_sign_up_breadcrumb'>
                        <span className='partner_sign_up_breadcrumb_first'>Corporate partner</span>
                        <span className='partner_sign_up_breadcrumb_second'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
                                <path d="M6 3L11 8L6 13" stroke="#FF4600" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Sign up your business
                        </span>
                    </p>
                </div>
                <div className="partner_sign_up_form_second_row_left_side_content">
                    <div className="partner_sign_up_left_container">
                        <p className="partner_sign_up_left_first_text">
                            become a corporate partner
                        </p>
                        <p className="partner_sign_up_left_second_text">
                            Let’s talk
                        </p>
                        <p className="partner_sign_up_left_third_text">
                            If Go121 sounds like a good fit for your brand, fill in the form.
                        </p>
                        <p className="partner_sign_up_left_fourth_text invisible">+27 083 987 0987</p>
                        <div className="partner_sign_up_left_ok_img_container">
                            <img src={OkImg} className="partner_sign_up_left_ok_img partner_sign_up_left_ok_img_desktop" alt="Okay" />
                            <img src={OkImgMobile} className="partner_sign_up_left_ok_img partner_sign_up_left_ok_img_mobile" alt="Okay" />
                        </div>
                    </div>
                </div>

                <div className="partner_sign_up_form_second_row_right_side_content">
                    <div className="partner_sign_up_form_container">
                        <div className="partner_sign_up_form_body">
                            <div className="partner_sign_up_form_group">
                                <img src={ThankyouImg} alt="" className="partner_sign_up_thank_you_img" />
                            </div>
                            <div className="partner_sign_up_form_group">
                                <div className="partner_sign_up_thank_you_text">Thank you</div>
                            </div>
                            <div className="partner_sign_up_form_group">
                                <div className="partner_sign_up_thank_you_description">Your email has been sent and you should receive a response in the next 2-5 working days.</div>
                            </div>
                            <div className="partner_sign_up_form_group">
                                <Link to={"/corporate-partner"} className="partner_sign_up_form_submit_btn text-decoration-none">
                                    <span>Back</span>
                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18" stroke="#FFBF00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
