import React from 'react'

export const GetStartedHeading = () => {
    return (
        <div className="header-section-white">
            <div className="get-started-heading-row grid">
                <div className="heading">
                    <p className="get-started-heading-first-line font-xsmall">Quick links</p>
                    <h1 className="get-started-heading-second-line font-large">New to Go121? Here are some pointers to help you get started</h1>
                </div>
            </div>
        </div>
    )
}
