import React from 'react'
import PosMachineImg from '../../assets/images/help center article/why_pos_machine_pic.png';

export const WhyGetPOSMachine = () => {
    return (
        <div className='header-section-white'>
            <div className='why_get_pos_machine grid'>
                <div className='why_get_pos_machine_first_row_content'>
                    <p className='why_get_pos_machine_first_row_content_first_line font-xsmall'>Help Centre
                        <span className='inside_content1'> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
                            <path d="M6 3L11 8L6 13" stroke="#FF4600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>Sales Agent</span>
                        <span className='inside_content2'> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
                            <path d="M6 3L11 8L6 13" stroke="#FF4600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>Why get a POS machine?
                        </span>
                    </p>
                    <h1 className='why_get_pos_machine_first_row_content_second_line font-large'>Why get a POS machine?</h1>
                    <div className='why_get_pos_machine_first_row_content_img'>
                        <img src={PosMachineImg} alt="loading" />
                    </div>
                </div>
                <div className='why_get_pos_machine_second_row_content'>
                    <h5 className='why_get_pos_machine_second_row_content_first_line font-medium'>Lorem ipsum dolor sit amet, consectetur adipiscing</h5>
                    <p className='why_get_pos_machine_second_row_content_second_line font-small'>Nunc, in quam cursus ipsum diam eget pharetra sed pulvinar. Tempus adipiscing sagittis vitae phasellus suspendisse risus. Habitant pellentesque feugiat turpis arcu. Amet vestibulum ipsum sem lobortis dictum augue sed felis. Varius dolor augue est congue semper egestas facilisis maecenas at. Proin cursus odio purus, velit adipiscing morbi. Id malesuada arcu penatibus vestibulum, ac.</p>

                    <p className='why_get_pos_machine_second_row_content_second_line font-small'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo sit lacus lectus viverra scelerisque. Nunc, in quam cursus ipsum diam eget pharetra sed pulvinar. Tempus adipiscing sagittis vitae phasellus suspendisse risus. Habitant pellentesque feugiat turpis arcu. Libero, augue adipiscing libero tempor quis risus ipsum tristique semper.
                    </p>
                </div>
            </div>
        </div>
    )
}
