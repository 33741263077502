import React from 'react'
import { Link, NavLink } from 'react-router-dom'
// import BecomeaPartnerMenAndWomenImg from '../../assets/images/become a partner/men_and_women_pic.png';
// import upArrowImg from '../../assets/images/what_is_go121/up_arrow.png';
// import downArrowImg from '../../assets/images/what_is_go121/down_arrow.png';
// import GreadingImg from '../../assets/images/become a partner/Greeting_pic.png';
import BecomeaPartnerManAndWomen from '../../assets/images/become a partner/man_and_woman_pic.png';
import BecomeaPartnerManAndWomenMobile from '../../assets/images/become a partner/man_and_woman_picM.png';
import GreetingImg from '../../assets/images/become a partner/Greeting_pic.png';
import GreetingImgMobile from '../../assets/images/become a partner/Greeting_picM.png';


export const Header = () => {
    const handleClick = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }
    return (
        <div className='header-section'>
            <div className='become_a_partner_header grid'>
                <div className='become_a_partner_header_left_side_content'>
                    <p className='become_a_partner_header_left_side_content_first_line font-xsmall'>Become a corporate partner</p>
                    <h1 className='become_a_partner_header_left_side_content_second_line font-large'>Reach your customers via a network of <span className='become_a_partner_header_left_side_content_second_line_inside_content_color'> highly trained and driven agents</span></h1>
                    <p className='become_a_partner_header_left_side_content_third_line font-medium'>Your brand will be rubbing shoulders with some of the best in SA.</p>
                    <NavLink to={"/signup-form"} onClick={handleClick} className='button_action corporate_partner_sign_up_your_business_width'>Sign up your business
                        <span className='btn-w ms-4'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                            <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>
                    </NavLink>

                </div>

                <div className='become_a_partner_header_men_pic'>
                    <img src={BecomeaPartnerManAndWomen} alt="loading" />
                </div>
                <div className='become_a_partner_header_men_pic_mobile'>
                    <img src={BecomeaPartnerManAndWomenMobile} alt="loading" />
                </div>
                <div className='become_a_partner_header_greeting_pic'>
                    <img src={GreetingImg} alt="loading" />
                </div>
                <div className='become_a_partner_header_greeting_pic_mobile'>
                    <img src={GreetingImgMobile} alt="loading" />
                </div>

                {/* <div className='become_a_partner_header_greating_pic'>
                    <img src={GreadingImg} alt="loading" />
                </div> */}
                {/* <div className='become_a_partner_header_right_side_imgs'>
            </div> */}
            </div>
        </div>
    )
}
