import React from 'react'
import { PrivacyPolicyContent } from '../../components/privacy-policy/privacy-policy-content'
import { PrivacyPolicyHeader } from '../../components/privacy-policy/privacy-policy-header'

export const PrivacyPolicy = () => {
    return (
        <div>
           <PrivacyPolicyHeader/> 
           <PrivacyPolicyContent/>
        </div>
    )
}
