import React from 'react'
import { Link } from 'react-router-dom';
import PeopleWithProductsGirlImg from "../../assets/images/home page images/Image5.png";
import PeopleWithProductsGirlImgMobile from "../../assets/images/home page images/Image5M.png";
import PeopleWithProductsGirlImgTablet2 from "../../assets/images/home page images/Image5T2.png";
import { NavLink } from 'react-router-dom';
// import BecomeAnAgentbtnImg from '../../assets/images/home page images/become_an_angent_btn.png'

export const PeopleWithProducts = () => {
    return (
        <div className="nabar-section">
            <div className="people-with-products grid">
                <div className="partner-left-side-img">
                    <img src={PeopleWithProductsGirlImg} alt="loading" />
                    
                </div>
                <div className="partner-left-side-img-mobile">
                <img src={PeopleWithProductsGirlImgMobile} alt="loading" />
                </div>
                <div className="partner-left-side-img-tablet2">
                <img src={PeopleWithProductsGirlImgTablet2} alt="loading" />
                </div>
                <div className="partner-right-side-content">
                    <p className="people-with-products-first-line font-xsmall">Why become A</p>
                    <h1 className="people-with-products-second-line font-large">Connecting people with products</h1>
                    <p className="people-with-products-third-line font-small">Our agents are on the ground promoting a range of brands directly to the community. Every agent is thoroughly vetted, self-motivated and is a trusted “1-2-1” point of connection. They are empowered to carry out SIM card sales, RICA  registration and to sell a wide range of value-added products. They also provide catalogue sales and conduct surveys on behalf of brands.</p>
                    <NavLink onClick={()=> {window.scrollTo({top:0, left:0, behavior:'smooth'})}} to="/become-go-121-agent" className='button_action'>Become an Agent
                        <span className='btn-w ms-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                            <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </span>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}
