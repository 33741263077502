import React, { useContext, useEffect, useState } from 'react'
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { FaqCard } from '../../constant/types';
import axios from '../../utils/api';
import { NavLink } from 'react-router-dom';

interface ContextAwareToggleProps {
    children: React.ReactFragment;
    eventKey: string;
    callback?: (arg: string) => void
}

interface ComponentProps {
    faqGUID?: string;
}

export const HereHelp: React.FC<ComponentProps> = ({ faqGUID }) => {

    const [faqCards, setFaqCards] = useState<FaqCard[]>([]);

    useEffect(() => {
        if (faqGUID) {
            axios.get(`api/Catalog/GetFAQsFromTag?FAQTagID=${faqGUID}`).then(({ data }) => {
                setFaqCards(data)
            })
        }
    }, [])

    const ContextAwareToggle: React.FC<ContextAwareToggleProps> = ({ children, eventKey, callback }) => {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <div className={`here_help_right_side_content_padding pt-3 pb-1 ${eventKey === '0' ? 'border-top' : ''}`} onClick={decoratedOnClick}>
                <div className='w-100'>
                    <p className="here_help_right_first_line font-small">{children}</p>
                </div>
                <div className="text-end">
                    <div className="plus_img">
                        {isCurrentEventKey ? (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.0711 12.0712H4.92893" stroke="#FF4600" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    if (faqCards.length > 0)
        return (
            <div className='header-section-gray'>
                <div className="here_help grid">
                    <div className="here_help_left_side_content">
                        <p className="here_help_left_side_first_line font-xsmall">FAQS</p>
                        <h1 className="here_help_left_side_second_line fontLarge">Stuck? We’re here to help</h1>
                        <NavLink onClick={()=> {window.scrollTo({top:0, left:0, behavior:'smooth'})}} to="/help-centre" className='button_action faq_styles'>Visit help centre
                            <span className='btnW ms-4'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
                                <path d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18" stroke="#FFBF00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            </span>
                        </NavLink>
                    </div>
                    <div className="here_help_right_side_content">
                        <div className="">
                            <Accordion>
                                {faqCards.map((card: FaqCard, key) => (
                                    <div className='border-bottom'>
                                        <ContextAwareToggle eventKey={key.toString()}>{card.question}</ContextAwareToggle>
                                        <Accordion.Collapse eventKey={key.toString()}>
                                            <>
                                                <div className='here_help_right_side_content_collapse_body mb-3'>
                                                    {card.shortAnswer}
                                                    {card.hasLongAnswer && <span className='here_help_right_side_content_collapse_readmore'>Read More</span>}
                                                </div>
                                            </>
                                        </Accordion.Collapse>
                                    </div>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        )
    else {
        return null;
    }
}
