import React from 'react'
import leftArrow from '../../assets/images/about us/left_arrow.svg';
import rightArrow from '../../assets/images/about us/right_arrow.png';

export const Testimonials = () => {
    return (
        <div className='sales-section'>
            <div className='about_us_testimonials grid'>
                <div className='about_us_testimonials_carousel'>
                    <div id="carouselExampleIndicators" className="carousel slide about_us_testimonial_carousel_flex" data-bs-ride="carousel">
                        <div className="carousel-inner about-us-testemonials-carousel-inner">
                            <div className="carousel-item active">
                                <div className='grid about-us-carousel-grid'>
                                    <div className='about_us_testimonials_carousel_content'>
                                        <p className='about_us_testimonials_carousel_content_first_line font-xsmall'>Testimonials</p>
                                        <h5 className='about_us_testimonials_carousel_content_second_line font-medium'>"Go121 is part of Flash so there’s lots of opportunities to grow within the group. The head office is amazing and the culture is the best I’ve ever experienced."</h5>
                                        <p className='about_us_testimonials_carousel_content_third_line font-small'>Frank - Employee</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='about_us_testimonial_carousel_buttons'>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
