import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../indx";

export interface SettingState {
  menuVisible: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: SettingState = {
  menuVisible: false,
  status: "idle",
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    showMenu: (state) => {
      console.log("her");
      state.menuVisible = true;
    },
    hideMenu: (state) => {
      state.menuVisible = false;
    },
  },
});

//actions
export const { showMenu, hideMenu } = settingSlice.actions;

//selectors
export const menuVisible = (state: RootState) => state.setting.menuVisible;

export default settingSlice.reducer;
