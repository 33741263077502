import React from 'react'
import TermsAndConditionsUpArrowImg from '../../assets/images/terms and conditions/terms_and_conditons_up_arrow.png';
import PrivacyAndPolicyGreatingImg from '../../assets/images/terms and conditions/privacy_and_policy_greating_pic.png';
import TermsAndConditionsUpArrowImgMobile from '../../assets/images/terms and conditions/terms_and_conditions_arrowM.png';
import PrivacyAndPolicyGreatingImgMobile from '../../assets/images/terms and conditions/privacy_and_policy_greating_picM.png';


export const PrivacyPolicyHeader = () => {
    return (
        <div className='header-section'>
            <div className='privacy_and_policy grid'>
                <div className='privacy_and_policy_up_arrow_img'>
                     <img src={TermsAndConditionsUpArrowImg} alt="loading" />
                </div>
                <div className='privacy_and_policy_up_arrow_img_mobile'>
                     <img src={TermsAndConditionsUpArrowImgMobile} alt="loading" />
                </div>
                <div className='privacy_and_policy_heading'>
                    <h1 className='privacy_and_policy_first_line'>Privacy Policy</h1>
                </div>
                <div className='privacy_and_policy_greatings_img'>
                    <img src={PrivacyAndPolicyGreatingImg} alt="loading" />
                </div>
                <div className='privacy_and_policy_greatings_img_mobile'>
                    <img src={PrivacyAndPolicyGreatingImgMobile} alt="loading" />
                </div>
            </div>
        </div>
    )
}
