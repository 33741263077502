import React from 'react'
import { SafetyFirst } from '../../components/verify-an-agent/safety-first';
import { StuckWeHereToHelp } from '../../components/verify-an-agent/stuck-we-here-to-help';
import { VerifyAnAgentHeader } from '../../components/verify-an-agent/verify-an-agent-header';
import { SalesAgent } from '../../components/become-an-agent/sales-agent';

export const VerifyAnAgent = () => {
    return (
        <div>
            <VerifyAnAgentHeader/>
            <SafetyFirst/>
            <StuckWeHereToHelp/>
            <SalesAgent/>
        </div>
    )
}
