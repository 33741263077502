import React from 'react'
import { baseURL } from '../../utils/api';
import { FaqCard } from '../../constant/types';

interface HelpCenterHeaderCardsProps {
    faqs: FaqCard[];
}

export const HelpCenterHeaderCards: React.FC<HelpCenterHeaderCardsProps> = ({ faqs }) => {
    return (
        <div className='header-section'>
            <div className='help_center_header_cards grid'>
                <div className="help_center_header_cards_row">
                    {faqs.map((faq) => (
                        <div className='help_center_card_div' key={faq.faqid}>
                            <div className="help_center_card_icon_div" style={{ backgroundColor: faq.iconColour }}>
                                {<img src={`${baseURL}image/${faq.icon.icon}`} alt="loading" />}
                            </div>
                            <h4 className='help_center_card_div_first_line font-small'>{faq.question}</h4>
                            <p className='help_center_card_div_second_line font-xsmall'>
                                {faq.shortAnswer}
                                {faq.hasLongAnswer && <span className='help_center_card_read_more'>Read More</span>}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
