import React from 'react'
import EmpowerCommunityBoyImg from '../../assets/images/about us/empower_community_left_side_pic.png';
import EmpowerCommunityBoyImgMobile from '../../assets/images/about us/empower_community_left_side_picM.png';

export const EmpowerCommunities = () => {
    return (
        <div className='header-section-white'>
            <div className='about_us_empower_community grid'>
                <div className='about_us_empower_community_left_side_img'>
                    <img src={EmpowerCommunityBoyImg} alt="" />
                </div>
                <div className='about_us_empower_community_left_side_img_mobile'>
                    <img src={EmpowerCommunityBoyImgMobile} alt="" />
                </div>

                <div className='about_us_empower_community_right_side_content'>
                    <p className='about_us_empower_community_right_side_content_first_line font-xsmall'>Playing our part</p>
                    <h1 className='about_us_empower_community_right_side_content_second_line font-large'>How we empower communities</h1>
                    <div className='empower_playing_our_part_list'>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="about_us_empower_community_right_side_content_third_line font-small">We create jobs.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="about_us_empower_community_right_side_content_third_line font-small">We offer people a convenient, safer and more cost-effective shopping environment. </li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="about_us_empower_community_right_side_content_third_line font-small">We build trust. Our sales agents are thoroughly vetted, self-motivated, and provide a trusted “1-2-1” connection in the community.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="about_us_empower_community_right_side_content_third_line font-small">We get the right products and services directly into the hands of the people who need them.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
