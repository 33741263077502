import React, { useEffect, useState } from 'react'
import { BrandsBenefit } from '../../components/home/brands-benefit'
import { GetStartedBtn } from '../../components/home/get-started/get-started-btn'
import { GetStartedCards } from '../../components/home/get-started/get-started-cards'
import { GetStartedHeading } from '../../components/home/get-started/get-started-heading'
import { Partner } from '../../components/home/partner'
import { PeopleWithProducts } from '../../components/home/people-with-products'
import { ReadyToBecomeSalesAgent } from '../../components/home/ready-to-become-sales-agent'
import { SalesAgent } from '../../components/home/sales-agent'
import { WelcomeToGo } from '../../components/home/welcome-to-go'
import { FaqCard } from '../../constant/types'
import axios from '../../utils/api';

export const HomePage: React.FC = () => {

    const [faqCards, setFaqCards] = useState<FaqCard[]>([]);

    useEffect(() => {
        axios.get(`api/Catalog/GetFAQsFromTag?FAQTagID=${process.env.REACT_APP_HOME_PAGE_FAQ_GUID}`).then(({ data }) => {
            setFaqCards(data)
        })
    }, [])
    return (
        <div>
            <WelcomeToGo />
            <BrandsBenefit />
            <SalesAgent />
            <Partner />
            <PeopleWithProducts />
            {faqCards.length > 0 &&
                <>
                    <GetStartedHeading />
                    <GetStartedCards faqCards={faqCards} />
                    <GetStartedBtn />
                </>
            }
            <ReadyToBecomeSalesAgent />
        </div>
    )
}
