import React from 'react'
import TermsAndConditionsUpArrowImg from '../../assets/images/terms and conditions/terms_and_conditons_up_arrow.png';
import TermsAndConditionsArrowImg from '../../assets/images/terms and conditions/terms_and_conditioons_arrow_pic.png';
import TermsAndConditionsUpArrowImgMobile from '../../assets/images/terms and conditions/terms_and_conditions_arrowM.png';
import TermsAndConditionsArrowImgMobile from '../../assets/images/terms and conditions/terms_and_conditions_arrow_picM.png';


export const TermsAndConditionsHeader = () => {
    return (
        <div className='header-section'>
            <div className='terms_and_conditions grid'>
                <div className='terms_and_conditions_up_arrow_img'>
                     <img src={TermsAndConditionsUpArrowImg} alt="loading" />
                </div>
                <div className='terms_and_conditions_up_arrow_img_mobile'>
                     <img src={TermsAndConditionsUpArrowImgMobile} alt="loading" />
                </div>
                <div className='terms_and_conditions_heading'>
                    <h1 className='terms_and_conditions_first_line font-large'>Terms &amp; Conditions</h1>
                </div>
                <div className='terms_and_conditions_arrow_img'>
                    <img src={TermsAndConditionsArrowImg} alt="loading" />
                </div>
                <div className='terms_and_conditions_arrow_img_mobile'>
                    <img src={TermsAndConditionsArrowImgMobile} alt="loading" />
                </div>
            </div>
        </div>
    )
}
