import React from 'react'
import cardOneImg from '../../assets/images/about us/our_values_pic1.png';
import cardTwoImg from '../../assets/images/about us/our_values_pic2.png';
import cardThreeImg from '../../assets/images/about us/our_values_pic3.png';
import leftArrow from '../../assets/images/about us/left_arrow.svg';
import rightArrow from '../../assets/images/about us/right_arrow.png';

export const OurValues = () => {
    return (
        <div className='header-section'>
            <div className='about_us_our_values grid'>
                <div className='about_us_our_values_heading'>
                    <h1 className='font-mlarge m-0'>Our values</h1>
                </div>
                <div className='about_us_our_values_cards_section'>
                    <div className='card_div one'>
                        <img src={cardThreeImg} alt="loading" />
                        <h4 className='card_div_first_line font-medium'>Our agents come first</h4>
                        <p className='card_div_second_line font-small'>By continually providing them with outstanding solutions and services, they can pass on the best products and services at the best price to their community customers. </p>
                    </div>
                    <div className='card_div two'>
                        <img src={cardTwoImg} alt="loading" />
                        <h4 className='card_div_first_line font-medium'>Always improving</h4>
                        <p className='card_div_second_line font-small'>We are continually looking for new ways to help our agents earn more and for their clients to save more by ​​using the latest technology and sophisticated data analysis.</p>
                    </div>
                    <div className='card_div three'>
                        <img src={cardOneImg} alt="loading" />
                        <h4 className='card_div_first_line font-medium'>Fast moving, long-term thinking </h4>
                        <p className='card_div_second_line font-small'>Everything we do is driven by the desire to do it smarter, better and make a long term difference to the informal market.</p>
                    </div>
                </div>

                <div className='about_us_our_values_cards_mobile'>
                    <div id="carouselOurvalues" className="carousel slide about_us_testimonial_carousel_flex" data-bs-ride="carousel">
                        <div className="carousel-indicators about_us_testimonials_indicators">
                            <button type="button" data-bs-target="#carouselOurvalues" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselOurvalues" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselOurvalues" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="about_us_testimonials_buttons">
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselOurvalues" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true">
                                    <img src={leftArrow} alt="loading" className="leftArrow" />
                                </span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselOurvalues" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <img src={rightArrow} alt="loading" className="rightArrow" />
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                        <div className="carousel-inner about-us-testemonials-carousel-inner">
                            <div className="carousel-item active">
                                <div className='card_div one'>
                                    <img src={cardThreeImg} alt="loading" />
                                    <h4 className='card_div_first_line font-medium m-0'>Our agents come first</h4>
                                    <p className='card_div_second_line font-small'>By continually providing them with outstanding solutions and services, they can pass on the best products and services at the best price to their community customers. </p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='card_div two'>
                                    <img src={cardTwoImg} alt="loading" />
                                    <h4 className='card_div_first_line font-medium'>Always improving</h4>
                                    <p className='card_div_second_line font-small'>We are continually looking for new ways to help our agents earn more and for their clients to save more by ​​using the latest technology and sophisticated data analysis.</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='card_div three'>
                                    <img src={cardOneImg} alt="loading" />
                                    <h4 className='card_div_first_line font-medium'>Fast moving, long-term thinking </h4>
                                    <p className='card_div_second_line font-small'>Everything we do is driven by the desire to do it smarter, better and make a long term difference to the informal market.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators  carousel_indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='card_div one'>
                                    <img src={cardThreeImg} alt="loading" />
                                    <h4 className='card_div_first_line font-medium m-0'>Our agents come first</h4>
                                    <p className='card_div_second_line font-small'>By continually providing them with outstanding solutions and services, they can pass on the best products and services at the best price to their community customers. </p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='card_div two'>
                                    <img src={cardTwoImg} alt="loading" />
                                    <h4 className='card_div_first_line font-medium'>Always improving</h4>
                                    <p className='card_div_second_line font-small'>We are continually looking for new ways to help our agents earn more and for their clients to save more by ​​using the latest technology and sophisticated data analysis.</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='card_div three'>
                                    <img src={cardOneImg} alt="loading" />
                                    <h4 className='card_div_first_line font-medium'>Fast moving, long-term thinking </h4>
                                    <p className='card_div_second_line font-small'>Everything we do is driven by the desire to do it smarter, better and make a long term difference to the informal market.</p>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
