import React from 'react'

export const WhyBecomeAnAgent = () => {
    return (
        <div className='header-section-white'>
            <div className="help_center_topic_why_become_an_angent grid">
                <div className='help_center_topic_why_become_an_angent_left_content'>
                    <p className='help_center_topic_why_become_an_angent_left_content_first_line font-xsmall'>FAQs</p>
                    <h1 className='help_center_topic_why_become_an_angent_left_content_second_line font-large'>Why become an agent?</h1>
                </div>
                <div className='help_center_topic_why_become_an_angent_right_content'>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header border-top help_center_topic_why_become_an_angent_right_content_h2" id="flush-headingOne">
                                <button className="accordion-button collapsed help_center_topic_why_become_an_angent_right_content_styles_and_font font-small" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    The benefits of becoming a Go121 Sales Agent
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body help_center_topic_why_become_an_angent_right_content_accordion_para font-xsmall">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header help_center_topic_why_become_an_angent_right_content_h2" id="flush-headingTwo">
                                <button className="accordion-button collapsed help_center_topic_why_become_an_angent_right_content_styles_and_font font-small" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    Finding customers
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body help_center_topic_why_become_an_angent_right_content_accordion_para font-xsmall">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header help_center_topic_why_become_an_angent_right_content_h2" id="flush-headingThree">
                                <button className="accordion-button collapsed help_center_topic_why_become_an_angent_right_content_styles_and_font font-small" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    Where can I buy a starter kit?
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body help_center_topic_why_become_an_angent_right_content_accordion_para font-xsmall">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header help_center_topic_why_become_an_angent_right_content_h2" id="flush-headingFour">
                                <button className="accordion-button collapsed help_center_topic_why_become_an_angent_right_content_styles_and_font font-small" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    How many agents will sell in my area?
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body help_center_topic_why_become_an_angent_right_content_accordion_para font-xsmall">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header border-bottom help_center_topic_why_become_an_angent_right_content_h2" id="flush-headingFifth">
                                <button className="accordion-button collapsed help_center_topic_why_become_an_angent_right_content_styles_and_font font-small" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifth" aria-expanded="false" aria-controls="flush-collapseFifth">
                                    What do I need to start selling
                                </button>
                            </h2>
                            <div id="flush-collapseFifth" className="accordion-collapse collapse" aria-labelledby="flush-headingFifth" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body help_center_topic_why_become_an_angent_right_content_accordion_para font-xsmall">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
