import React from 'react';
import { FaqCategory } from '../../constant/types';
import HelpCenterFaqCategoryButton from './help-center-faq-category';

interface HelpCenterHeaderProps {
    faqCategories: FaqCategory[];
    selectedFaqCategory?: FaqCategory | null;
    onClickCategory?: (category: FaqCategory) => void
}

export const HelpCenterHeader: React.FC<HelpCenterHeaderProps> = ({ faqCategories, selectedFaqCategory, onClickCategory }) => {

    return (
        <div className='header-section'>
            <div className='help_center_header grid'>
                <div className='help_center_header_content'>
                    <h1 className='help_center_header_content_first_line font-large'>How can we help you?</h1>
                    <div className='help_center_header_content_three_btns'>
                        {faqCategories.map((category) => (
                            <HelpCenterFaqCategoryButton
                                key={category.faqCategoryID}
                                active={category.faqCategoryID === selectedFaqCategory?.faqCategoryID}
                                onClick={() => onClickCategory && onClickCategory(category)}
                                {...category}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
