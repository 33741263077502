import React from 'react'
import { TheBenifits } from '../../components/help-center-articale/the-benifits'
import { WasThisArticaleHelpful } from '../../components/help-center-articale/was-this-articale-helpful'
import { WhyGetPOSMachine } from '../../components/help-center-articale/why-get-pos-machine'
import { PopularSalesAgentBelowCards } from '../../components/help-center/popular-sales-agent-below-cards'

export const HelpCenterArticale = () => {
    return (
        <div>
            <WhyGetPOSMachine/>
            <TheBenifits/>
            <WasThisArticaleHelpful/>
            <PopularSalesAgentBelowCards />
        </div>
    )
}
