import React, { useEffect, useState } from "react";
import BgImg from "../../assets/images/home page images/backgroundArrow.png";
// import { Link } from 'react-router-dom'
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import ThumbsUpIMg from "../../assets/images/ThumbsUp_pic.png";
import Notification, { NotificationState } from "../../components/notification";
import axios from "../../utils/api";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Link } from "react-router-dom";
// import StyleRoot  from 'radium';

interface Coordinate {
  lat: number;
  lng: number;
}

const googleKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
const proxyApi = process.env.REACT_APP_PROXY_API_URL;

export const AddressDetails: React.FC = (props: any) => {
  const [currentPosition, setCurrentPosition] = useState<Coordinate | null>(
    null
  );

  const [currentAddress, setCurrentAddress] = useState<string>("");

  const [address, setAddress] = useState<string>("");

  const [useCurrentLocation, setUseCurrentLocation] = useState<boolean>(false);

  const [center, setCenter] = useState<Coordinate>({
    lat: -33.93476552120989,
    lng: 18.472637807416824,
  });
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);

  const [confirmCriminalRecord, setConfirmCriminalRecord] =
    useState<boolean>(false);

  const [notification, setNotification] = useState<NotificationState>({
    text: "",
    type: "success",
    visible: false,
  });

  const [agentID, setAgentID] = useState<string>("");

  useEffect(() => {
    if (window.navigator) {
      window.navigator.geolocation.getCurrentPosition((e) => {
        setCurrentPosition({
          lat: e.coords.latitude,
          lng: e.coords.longitude,
        });
        setCenter({
          lat: e.coords.latitude,
          lng: e.coords.longitude,
        });

        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.coords.latitude},${e.coords.longitude}&key=${googleKey}`
          )
          .then(({ data }) => {
            if (data.results?.length > 0) {
              setAddress(data.results[0].formatted_address);
              setCurrentAddress(data.results[0].formatted_address);
            }
          });
      });
    }
    let pAgentID = localStorage.getItem("agentId");
    if (!pAgentID) {
      props.history.push("/personal-details");
    } else {
      setAgentID(pAgentID);
    }
  }, []);

  const handleUseCurrentLocation = () => {
    if (currentPosition) {
      setUseCurrentLocation(!useCurrentLocation);
      setCenter(currentPosition);
      setAddress(currentAddress);
    }
  };

  const handleChangeAddress = (address: string) => {
    setAddress(address);
  };

  const selectPlace = (e: any) => {
    geocodeByPlaceId(e.place_id).then(([results]: any) => {
      setAddress(e.description);
      setCenter({
        lat: results.geometry.location.lat(),
        lng: results.geometry.location.lng(),
      });
    });
  };

  const canSubmit = (): boolean => {
    if (!agreeTerms || !confirmCriminalRecord) {
      return false;
    }
    if (!useCurrentLocation && address === "") {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    let result: Coordinate;
    if (useCurrentLocation && currentPosition) {
      result = currentPosition;
    } else {
      result = center;
    }

    axios
      .post(`${proxyApi}api/proxy_post/Agent/${agentID}/Address`, {
        address: useCurrentLocation ? "" : address,
        addressLatitude: useCurrentLocation ? center.lat : "",
        addressLongitude: useCurrentLocation ? center.lng : "",
        latitude: currentPosition?.lat,
        longitude: currentPosition?.lng,
        typeId: 0,
      })
      .then(({ data }) => {
        axios
          .post(`${proxyApi}api/proxy_post/Agent/${agentID}/AcceptAgent`, {
            termsAndConditions: true,
            termsAndConditionsVersion: 0,
            criminalRecord: true,
            requestedBy: "string",
          })
          .then(({ data }) => {
            props.history.push("/application-form-complete");
          })
          .catch((error) => {
            setNotification({
              text: "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
              type: "warn",
              visible: true,
            });
          });
      })
      .catch((error) => {
        setNotification({
          text: "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
          type: "warn",
          visible: true,
        });
      });
  };

  return (
    <div className="nabar-section">
      {notification.visible && (
        <Notification type={notification.type} text={notification.text} />
      )}
      <div className="location_terms grid">
        <div className="location_terms_breadcrumb">
          <Link to={"/become-go-121-agent"}>
            <span className="location_terms_breadcrumb_first">
              Become an Agent
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6 3L11 8L6 13"
                  stroke="#FF4600"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </Link>
          <Link to={"/personal-details"}>
            <span className="location_terms_breadcrumb_first">
              Personal details
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6 3L11 8L6 13"
                  stroke="#FF4600"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </Link>
          <Link to={"/otp"}>
            <span className="location_terms_breadcrumb_first">
              Enter OTP
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6 3L11 8L6 13"
                  stroke="#FF4600"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </Link>
          <span className="location_terms_breadcrumb_second">
            Address details
          </span>
        </div>
        <div className="location_terms_left_side_container">
          <div className="location_terms_form">
            <div className="location_terms_form_header">
              <div className="location_terms_form_title">Almost done</div>
              <div className="location_terms_form_step">Step 3/3</div>
            </div>
            <div className="location_terms_form_body">
              <div className="location_terms_form_question">
                Is this your address?
              </div>
              <LoadScript
                googleMapsApiKey={googleKey || ""}
                libraries={[
                  "drawing",
                  "geometry",
                  "localContext",
                  "places",
                  "visualization",
                ]}
              >
                <GooglePlacesAutocomplete
                  onSelect={selectPlace}
                  suggestionsClassNames={{
                    container:
                      "location_terms_form_address_autocomplete_prediction_wrapper",
                    suggestion:
                      "location_terms_form_address_autocomplete_prediction",
                  }}
                  renderInput={(inputProps) => (
                    <div className="location_terms_form_address_autocomplete_wrapper">
                      <input
                        {...inputProps}
                        onChange={(e) => {
                          handleChangeAddress(e.target.value);
                          if (e.target.value.length > 6) inputProps.onChange(e);
                        }}
                        value={address}
                        onFocus={() => setAddress("")}
                      />
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.639 4C15.8576 4 19.2781 7.41968 19.2781 11.639C19.2781 15.8584 15.8576 19.2781 11.639 19.2781C7.41968 19.2781 4 15.8584 4 11.639C4 7.41968 7.41968 4 11.639 4Z"
                          stroke="#1C1CA8"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.0371 17.037L20.0001 20"
                          stroke="#1C1CA8"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                />
              </LoadScript>
              <div
                className="location_terms_use_current_location"
                style={{
                  cursor: currentPosition ? "pointer" : "not-allowed",
                  color: useCurrentLocation ? "#41AD49" : "#1C1CA8",
                }}
                onClick={() => {
                  handleUseCurrentLocation();
                }}
              >
                Use current location
                {useCurrentLocation ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11Z"
                      fill="#41AD49"
                    />
                    <path
                      d="M16 8L9.125 15L6 11.8182"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 20C6.02944 20 2 15.9706 2 11H0C0 17.0751 4.92487 22 11 22V20ZM20 11C20 15.9706 15.9706 20 11 20V22C17.0751 22 22 17.0751 22 11H20ZM11 2C15.9706 2 20 6.02944 20 11H22C22 4.92487 17.0751 0 11 0V2ZM11 0C4.92487 0 0 4.92487 0 11H2C2 6.02944 6.02944 2 11 2V0Z"
                      fill="#41AD49"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M24 11H21.949C21.479 6.283 17.717 2.521 13 2.051V0H11V2.051C6.283 2.521 2.521 6.283 2.051 11H0V13H2.051C2.522 17.717 6.283 21.479 11 21.949V24H13V21.949C17.717 21.478 21.479 17.717 21.949 13H24V11ZM13 19.931V18H11V19.931C7.388 19.477 4.523 16.612 4.069 13H6V11H4.069C4.523 7.388 7.388 4.523 11 4.069V6H13V4.069C16.612 4.522 19.477 7.388 19.931 11H18V13H19.931C19.477 16.612 16.612 19.477 13 19.931Z"
                      fill="#FF4600"
                    />
                    <path
                      d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
                      fill="#FF4600"
                    />
                  </svg>
                )}
              </div>
              <div
                className="location_terms_agree_policy_line"
                onClick={() => setAgreeTerms(!agreeTerms)}
              >
                {agreeTerms ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11Z"
                      fill="#1C1CA8"
                    />
                    <path
                      d="M16 8L9.125 15L6 11.8182"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 20C6.02944 20 2 15.9706 2 11H0C0 17.0751 4.92487 22 11 22V20ZM20 11C20 15.9706 15.9706 20 11 20V22C17.0751 22 22 17.0751 22 11H20ZM11 2C15.9706 2 20 6.02944 20 11H22C22 4.92487 17.0751 0 11 0V2ZM11 0C4.92487 0 0 4.92487 0 11H2C2 6.02944 6.02944 2 11 2V0Z"
                      fill="#1C1CA8"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="21"
                      height="21"
                      rx="10.5"
                      fill="#EEEEF0"
                      stroke="#C3C4C7"
                    />
                  </svg>
                )}{" "}
                I agree to Go121’s Terms &amp; Conditions
              </div>
              <div
                className="location_terms_agree_policy_line"
                onClick={() => setConfirmCriminalRecord(!confirmCriminalRecord)}
              >
                {confirmCriminalRecord ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11Z"
                      fill="#1C1CA8"
                    />
                    <path
                      d="M16 8L9.125 15L6 11.8182"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 20C6.02944 20 2 15.9706 2 11H0C0 17.0751 4.92487 22 11 22V20ZM20 11C20 15.9706 15.9706 20 11 20V22C17.0751 22 22 17.0751 22 11H20ZM11 2C15.9706 2 20 6.02944 20 11H22C22 4.92487 17.0751 0 11 0V2ZM11 0C4.92487 0 0 4.92487 0 11H2C2 6.02944 6.02944 2 11 2V0Z"
                      fill="#1C1CA8"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="21"
                      height="21"
                      rx="10.5"
                      fill="#EEEEF0"
                      stroke="#C3C4C7"
                    />
                  </svg>
                )}{" "}
                I confirm that I do not have a criminal record
              </div>
              <button
                className="button_action location_terms_submit_btn_width_height"
                disabled={!canSubmit()}
                onClick={() => handleSubmit()}
              >
                Submit
                <span className="btn-w ms-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22 24"
                    fill="none"
                  >
                    <path
                      d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18"
                      stroke="#FFBF00"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="location_terms_right_side_container">
          <div className="address_details_map_div">
            <LoadScript
              googleMapsApiKey={googleKey || ""}
              libraries={[
                "drawing",
                "geometry",
                "localContext",
                "places",
                "visualization",
              ]}
            >
              <GoogleMap center={center} zoom={13}>
                <>{currentPosition && <Marker position={center}></Marker>}</>
              </GoogleMap>
            </LoadScript>
            <div className="address_details_right_side_div_thumbsUp_pic">
              <img src={ThumbsUpIMg} alt="loading" />
            </div>
          </div>
        </div>
        <div className="location_terms_arrow_img">
          <img src={BgImg} alt="loading" />
        </div>
      </div>
    </div>
  );
};
