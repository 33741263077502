import React from 'react'
import { FaqCard } from '../../../constant/types';
import { baseURL } from '../../../utils/api';

interface ComponentProps {
    faqCards: FaqCard[];
}

export const GetStartedCards: React.FC<ComponentProps> = ({ faqCards }) => {
    return (
        <div className="header-section-white">
            <div className="get-started-card-section-row grid">
                <div className='get-started-card-section-row-cards'>
                    {faqCards.map((card: FaqCard) => (
                        <div className="get-started-card">
                            <div className="get-started-card-icon" style={{ backgroundColor: card.iconColour }}>
                                <img src={`${baseURL}image/${card.icon.icon}`} alt="" />
                            </div>
                            <p className="get-started-card-section-row-first-line font-small">
                                {card.question}
                            </p>
                            <p className="get-started-card-section-row-second-line font-xsmall">
                                {card.shortAnswer}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
