import React from 'react'
import { SafetyFirst } from '../../components/verify-an-agent/safety-first';
import { StuckWeHereToHelp } from '../../components/verify-an-agent/stuck-we-here-to-help';
import { SalesAgent } from '../../components/become-an-agent/sales-agent';
import { VerifyAnAgnetHeaderError } from '../../components/verify-an-agent-header-error/verify-an-agnet-header-error';

export const VerifyAnAgentError = () => {
    return (
        <div>
            <VerifyAnAgnetHeaderError />
            <SafetyFirst />
            <StuckWeHereToHelp />
            <SalesAgent />
        </div>
    )
}
